import cssVars from 'css-vars-ponyfill'
import WOW from 'wow.js'
import 'jquery-match-height/dist/jquery.matchHeight-min'

const dhsvCommon = {
  stickyBanner() {
    let scrollTop
    const $body = $('body')
    const distance = $('.banner__top').outerHeight(true)

    function bodyMargin() {
      const headerHeight = $('.banner').outerHeight(true, true)
      if (!$('.banner').hasClass('banner__transparent')) {
        $body.css('margin-top', headerHeight)
        document.documentElement.style.setProperty(
          '--header-height',
          headerHeight + 'px'
        )
      }
    }

    function handleScroll() {
      scrollTop = $(window).scrollTop()

      if (scrollTop <= distance) {
        $body.removeClass('sticky-nav')
      } else {
        $body.addClass('sticky-nav')
      }
    }

    $(window).on('scroll', this.throttled(10, handleScroll))
    $(window).on('resize', this.throttled(10, bodyMargin))
    //$(window).on('load', this.throttled(10, bodyMargin))
    $(document).ready(this.throttled(10, bodyMargin))
    handleScroll()
  },
  throttled(delay, fn) {
    let lastCall = 0
    return function(...args) {
      const now = new Date().getTime()
      if (now - lastCall < delay) {
        return
      }
      lastCall = now
      return fn(...args)
    }
  },
  setBodyWidth() {
    cssVars({
      variables: {
        '--body-width': document.body.clientWidth + 'px',
        '--col-width': $('.wrap.container-fluid').outerWidth() / 12 + 'px',
      },
    })
  },
  smoothScroll(link, e) {
    if (e) e.preventDefault()
    console.log(link) //eslint-disable-line
    if (link === '#signup' || link === '#') {
      history.replaceState(null, null, link)
      handleModalState()
    } else {
      let destination = 0

      var hash = false
      if (!link && window.location.hash) {
        hash = window.location.hash
      } else if (link && link.indexOf('#') == 0) {
        hash = link
      } else if (
        link &&
        location.pathname.replace(/^\//, '') ==
          link.pathname.replace(/^\//, '') &&
        location.hostname == link.hostname
      ) {
        hash = link.hash
      }

      if (hash && $(hash).length) {
        destination = $(hash).offset().top - 75
        history.replaceState(null, null, hash)
      }

      $(window).trigger('hashchange')

      $('html, body').animate(
        {
          scrollTop: destination,
        },
        600
      )
    }
  },
  gfieldButtonsRemove() {
    $('.gform_wrapper ').each(function() {
      $(this)
        .find('input[type="checkbox"], input[type="radio"]')
        .closest('.gfield')
        .addClass('no_decor')
      if ($(this).find('.gfield .btn').length > 0) {
        $(this)
          .find('.gform_footer')
          .css({
            position: 'absolute',
            left: '-9999px',
          })
      }
    })
  },

  wowAnimation() {
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 200,
      mobile: false,
      live: true,
    })
    wow.init()
  },
  mobileMenu() {
    $(document).on('click', '.js-toggle-menu', e => {
      e.preventDefault()
      $('header.banner nav.nav-primary .ubermenu').toggleClass(
        'ubermenu-responsive-collapse'
      )
      //$('header.banner nav.nav-primary .ubermenu').slideToggle()
      $('.banner__top').slideToggle()
      $('html').toggleClass('active')
      $('.js-toggle-menu').toggleClass('open ubermenu-responsive-toggle-open')
    })
  },
  formLabelAnimation() {
    $(
      'input:not([type="checkbox"]):not([type="radio"]),textarea,select',
      '.gfield'
    ).each(function() {
      $(this).attr('placeholder', '')
      if (
        !$(this)
          .closest('.gfield')
          .hasClass('active')
      ) {
        if ($(this).val() !== '') {
          $(this).addClass('active')
          $(this)
            .closest('.gfield')
            .addClass('active')
            .find('.gfield_label')
            .addClass('active')
        }
        $(this).on('focusin', function() {
          $(this)
            .closest('.gfield')
            .find('.gfield_label')
            .addClass('active')
        })

        $(this).on('focusout', function() {
          if (!$(this).val()) {
            $(this)
              .closest('.gfield')
              .find('.gfield_label')
              .removeClass('active')
          }
        })
      }

      $(this).on('focusin', function() {
        $(this)
          .closest('.gfield')
          .addClass('focusin')
      })

      $(this).on('focusout', function() {
        $(this)
          .closest('.gfield')
          .removeClass('focusin')
      })
    })

    $('.ginput_complex').each(function() {
      const inputs = $(this).find('input')

      inputs.each(function() {
        if ($(this).val() !== '') {
          $(this).addClass('active')
        }
      })

      inputs.on('input focusin focusout change', function() {
        if ($(this).val() !== '' || $(this).is(':focus')) {
          $(this).addClass('active')
        } else {
          $(this).removeClass('active')
        }
      })
    })
  },
  init() {
    this.stickyBanner()
    this.gfieldButtonsRemove()
    this.setBodyWidth()
    this.wowAnimation()
    this.mobileMenu()
    this.formLabelAnimation()
    handleSignupModal()

    if (window.location.hash) {
      this.smoothScroll(window.location.hash)
    }

    $(window).on('load', function() {
      $('body').removeClass('not_visible')
    })

    $('body').on(
      'click',
      'a[href^="#"]:not([href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-accordion],[data-vc-tabs])',
      e => {
        this.smoothScroll($(e.currentTarget).attr('href'), e)
      }
    )
    jQuery(document).on('gform_post_render', function() {
      setTimeout(function() {
        dhsvCommon.gfieldButtonsRemove()
      }, 500)

      dhsvCommon.formLabelAnimation()
    })
    $(window).scroll(function() {
      if ($(window).scrollTop() > 300) {
        $('.scrollToTop').addClass('visible')
      } else {
        $('.scrollToTop').removeClass('visible')
      }
    })

    $('.scrollToTop').click(function() {
      $('html, body').animate({ scrollTop: 0 }, 1000)
      return false
    })

    //navigation icon
    $('.js-menu-button').on('click', function() {
      $(this).toggleClass('open')
      $('.nav-primary > .ubermenu').toggleClass('ubermenu-responsive-collapse')
      $('body').toggleClass('menu_opened')
    })

    //Footer sitemap
    $('.js-sitemap_link').on('click', function() {
      $(this).toggleClass('opened')
      let $sitemap = $('.sitemap')
      $sitemap.toggleClass('visible')
      if ($sitemap.hasClass('visible')) {
        $sitemap.slideDown().css('display', 'flex')
      } else {
        $sitemap.slideUp()
      }
    })
  },
  onResize() {
    $(window).on('resize', () => {
      this.setBodyWidth()
    })
  },
}

export default {
  init() {
    // JavaScript to be fired on all pages
    dhsvCommon.init()
    dhsvCommon.onResize()
    window.addEventListener('load', parallaxInit)
    window.addEventListener('scroll', parallaxHandler)
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}

function parallaxInit() {
  $('.parallax-item').each(function() {
    const el = $(this)
    el.wrap('<div class="parallax-wrapper"></div>')
    //const wrapper = $(this).parents('.parallax-wrapper')
  })
}

function parallaxHandler() {
  $('.parallax-wrapper').each(function() {
    const $wrapper = $(this)
    const $item = $wrapper.find('.parallax-item')
    const speed = $item.data('speed')
    const itemcenter = $item.height() / 2
    const scrollTop = window.scrollY
    const halfWinH = window.innerHeight / 2
    const windowCenter = scrollTop + halfWinH
    const elOffset = $wrapper.offset().top
    const paraVal = elOffset + itemcenter - windowCenter
    $item.css({
      transform: `translateY(${paraVal / speed}px)`,
    })
  })
}

function openSignupModal() {
  //.set_user_mail class for gfield
  const mail = window.localStorage.getItem('dhsv_user_mail')
  const firstField = document.querySelector('.signup-modal input')
  const mailField = document.querySelector('.set_user_mail input')
  const mailLabel = document.querySelector('.set_user_mail .gfield_label')
  if (mailField) {
    mailField.value = mail
    mailLabel.classList.add('active')
    if ('createEvent' in document) {
      var evt = document.createEvent('HTMLEvents')
      evt.initEvent('change', false, true)
      mailField.dispatchEvent(evt)
    } else mailField.fireEvent('onchange')
    firstField.focus()
  }
  document.getElementById('signup_modal').classList.add('open')
}

const handleModalState = () => {
  if (window.location.hash === '#signup') {
    openSignupModal()
  } else {
    document.getElementById('signup_modal').classList.remove('open')
  }
}

function handleSignupModal() {
  window.addEventListener('load', handleModalState)
  window.addEventListener('hashchange', handleModalState)
  //const closeButton = document.querySelector('.close_signup_modal')
  const openButton = document.querySelector('.open_signup_modal')

  if (openButton) {
    openButton.addEventListener('click', () => {
      window.location = '#signup'
    })
  }
}
