import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_contact_form()
})

$(window).on('resize', function() {
  dhsv_vc_contact_form()
})

function dhsv_vc_contact_form() {
  $('.dhsv_vc_contact_form').each(function() {
    let contentHeight = $(this)
      .find('.top_content')
      .outerHeight(true, true)
    if ($(window).width() > 1200) {
      $(this)
        .find('.gform_wrapper')
        .css('margin-top', '-' + contentHeight + 'px')
    } else {
      $(this)
        .find('.gform_wrapper')
        .css('margin-top', '')
    }
  })
}
