function dhsv_vc_anchor_links() {
  $('.tabs_horizontal').each(function() {
    let $this = $(this)
    let $list = $this.find('.list_element').clone()
    $this.find('.list_element').remove()
    $list.appendTo($this.find('.tabs_navigation__list'))
    $('.tabs_navigation__list .list_element:first-of-type').addClass('active')
    $this.find('.tab_content .tabs__item:first-of-type').addClass('active')
  })
}

$(window).load(function() {
  dhsv_vc_anchor_links()
})

$(document).on('click', '.list_element', function() {
  if ($(this).hasClass('active')) {
    return false
  } else {
    let $parent = $(this).closest('.tabs_horizontal')
    let $content = $(this).data('item')
    $parent
      .find('.list_element')
      .removeClass('active')
      .end()
      .find('.tabs__item')
      .removeClass('active')
      .end()
      .find('[data-content=' + $content + ']')
      .addClass('active')
    $(this).addClass('active')
  }
})
