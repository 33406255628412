import $ from 'jquery'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min'

//$(document).ready(dhsv_vc_hero_section())
$(window).on('load', function() {
  // dhsv_vc_hero_section()
})

$(window).on('resize', function() {
  // dhsv_vc_hero_section()
})

// function dhsv_vc_hero_section() {
//   $('.hero_section').each(function() {
//     let $this = $(this)
//     if ($(window).width() < 992) {
//       $this.find('.hero_section__two_images').mCustomScrollbar({
//         axis: 'x',
//         scrollbarPosition: 'inside',
//         mouseWheel: {
//           enable: true,
//         },
//         contentTouchScroll: true,
//         advanced: { updateOnContentResize: true },
//       })
//     } else {
//       $this.find('.hero_section__two_images').mCustomScrollbar('destroy')
//     }
//   })
// }
